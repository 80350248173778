<template>
  <div>
    <v-container class="mb-4">
      <v-row class="align-center justify-center">
        <v-spacer />
        <v-btn text color="danger" to="/document/200310"
          ><v-icon left>mdi-download</v-icon> PDF</v-btn
        >
        <v-btn
          text
          color="info"
          @click="apiIcal('holiday')"
          class="ml-2"
          v-if="$root.settings.showIcal"
          ><v-icon left>mdi-calendar-import</v-icon> iCal</v-btn
        >
      </v-row>
    </v-container>
    <v-card flat :loading="loading" min-height="200px">
      <v-card v-for="schoolyear in items" :key="schoolyear.id" class="mb-4">
        <v-system-bar flat>{{
          schoolyear.description.replace("SJ ", "Schuljahr 20")
        }}</v-system-bar>
        <v-list>
          <template v-for="(holiday, index) in schoolyear.holidays">
            <v-divider inset :key="'divider' + holiday.id" v-if="index > 0" />
            <HolidayItem
              :key="holiday.id"
              :value="holiday"
              @click.native="showHoliday(holiday)"
            />
          </template>
        </v-list>
      </v-card>
    </v-card>
    <v-bottom-sheet
      min-width="350px"
      max-width="500px"
      v-model="selectedOpen"
      :close-on-content-click="false"
    >
      <v-card tile>
        <HolidayDetail :value="selectedHoliday" />

        <v-divider />
        <v-card-actions>
          <v-btn block text color="primary" @click="selectedOpen = false">
            Schliessen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import HolidayDetail from "@/components/HolidayDetail";
import HolidayItem from "@/components/HolidayItem";

export default defineComponent({
  name: "Holidays",
  components: { HolidayDetail, HolidayItem },
  data() {
    return {
      loading: false,
      items: [],
      selectedOpen: false,
      selectedHoliday: {},
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const currentSchoolYear = await this.apiList({
        resource: "common/schoolyear?current",
      });
      let schoolYears = await this.apiList({
        resource: "common/schoolyear/",
      });

      let index = schoolYears.findIndex((el) => el.id == currentSchoolYear.id);
      schoolYears = schoolYears.slice(index);

      for (let i = 0; i < schoolYears.length; i++) {
        schoolYears[i].holidays = await this.apiList({
          resource: "common/holiday/",
          query: `startDate=${schoolYears[i].startDate}&endDate=${schoolYears[i].endDate}`,
        });
      }
      this.items = schoolYears;
      this.loading = false;
    },
    showHoliday(holiday) {
      this.selectedHoliday = { holiday: holiday };
      this.selectedOpen = true;
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
