<template>
  <v-list-item link v-bind="$attrs" :class="value.current ? 'current' : ''">
    <ListIcon>
      <v-icon color="warning">{{ holidayIcon }}</v-icon>
    </ListIcon>
    <v-list-item-content>
      <v-list-item-title>{{ name }}</v-list-item-title>
      <v-list-item-subtitle>{{ formatDatespan }}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="$root.settings.showIcal">
      <v-btn
        outlined
        text
        color="info"
        small
        @click="apiIcal('holiday', value.id)"
        ><v-icon left>mdi-calendar-import</v-icon> iCal</v-btn
      >
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import { defineComponent } from "vue";
import { formatDatespan } from "common/utils/date";
import { holidayIcon } from "common/utils/icons";
export default defineComponent({
  name: "HolidayItem",
  props: {
    value: null,
  },
  computed: {
    name() {
      if (!this.value) {
        return "";
      }
      return this.value.name;
    },
    holidayIcon() {
      return holidayIcon(this.value);
    },
    formatDatespan() {
      if (!this.value) {
        return "";
      }
      return formatDatespan(this.value.startDate, this.value.endDate);
    },
  },
});
</script>

<style scoped>
.current {
  border-left: 2px solid red;
}
</style>
